import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  useQueryClient,
} from "react-query";
import { useParams } from "react-router-dom";
import { WretcherResponse } from "wretch";

import {
  PER_PAGE,
  BUNDLES_PER_PAGE,
  VARIANT_SEARCH_PAGE_SIZE,
} from "../constants";

import {
  getShop,
  updateShop,
  searchShops,
  getProductVariants,
  updateProductVariant,
  getProductGroups,
  updateProductGroup,
  deleteProductGroup,
  signOut,
  createProductGroup,
  createBrandChannel,
  getBundles,
  updateBundle,
  deleteBundle,
  createBundle,
  createUpload,
  getAnalytics,
  getAnalyticsDashboard,
  getShopFonts,
  getBrandChannels,
  getCodes,
  createCode,
  getCode,
  updateCode,
  searchCollections,
  createCollection,
  buildSettingsFetchFn,
  buildSettingsUpdateFn,
  getIdentity,
  addToProductGroup,
  getShopSettings,
  updateShopSettings,
} from "../api";

import {
  Shop,
  ProductVariant,
  ProductGroup,
  Bundle,
  BrandChannel,
  ApiError,
  DashboardAnalytics,
  DateRange,
  Pagination,
  Font,
  Code,
  CreateUploadParams,
  CreateBrandChannel,
  UploadUrls,
  AnalyticsDashboard,
  ShopSettings,
} from "../interfaces";
import * as queryString from "querystring";
import { useToastStore } from "../stores";

export function useShop(id: string, config?: UseQueryOptions<Shop>) {
  return useQuery(["shop", id], () => getShop(id), config);
}

export function useUpdateShop(config?: UseQueryOptions<Shop, ApiError>) {
  return useMutation(updateShop, config);
}

export function useShopSettings(
  shopId: string,
  config?: UseQueryOptions<ShopSettings>
) {
  return useQuery(
    ["shopSettings", shopId],
    () => getShopSettings(shopId),
    config
  );
}

export function useUpdateShopSettings(
  shopId: string,
  config?: UseQueryOptions<ShopSettings, Error>
) {
  const queryClient = useQueryClient();
  const showToast = useToastStore((state) => state.showToast);

  return useMutation(updateShopSettings, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["shopSettings", shopId]);
      showToast("Settings updated successfully.");
    },
    onError: () => {
      showToast("Failed to update settings.");
    },
    ...config,
  });
}

export function useSearchShops(
  search: string,
  config?: UseQueryOptions<Shop[]>
) {
  return useQuery(["shops", search], () => searchShops(search), {
    ...config,
    // Don't trigger query unless "search" is a truthy value
    enabled: Boolean(search),
  });
}

export function useSearchProductVariants({
  queryParams,
  shopId,
}: {
  queryParams: Record<string, string>;
  shopId: string;
}) {
  return useVariants({
    id: shopId,
    queryParams,
    page: 1,
    pageSize: VARIANT_SEARCH_PAGE_SIZE,
  });
}
export function generateQueryString(
  queryParams = {},
  page: number | null,
  pageSize: number = PER_PAGE
) {
  const pageParams =
    page === null
      ? {}
      : {
          "page[number]": page,
          "page[size]": pageSize,
        };

  const mergedQueryParams = {
    ...queryParams,
    ...pageParams,
  };

  return queryString.stringify(mergedQueryParams);
}

export function useVariants(
  options: {
    id: string;
    queryParams?: { [key: string]: string };
    page?: number;
    pageSize?: number;
  },
  config?: UseQueryOptions<{
    variants: ProductVariant[];
    pagination: Pagination;
  }>
) {
  const { id, queryParams = {}, page = null, pageSize = PER_PAGE } = options;

  const query = generateQueryString(queryParams, page, pageSize);

  return useQuery(
    ["productVariants", id, query],
    () => getProductVariants(id, query),
    config
  );
}

export function useUpdateProductVariant(
  config?: UseMutationOptions<ProductVariant, ApiError, Partial<ProductVariant>>
) {
  return useMutation<ProductVariant, ApiError, Partial<ProductVariant>>(
    updateProductVariant,
    config
  );
}

export function useProductGroups(
  id: string,
  page: number,
  config?: UseQueryOptions<{
    productGroups: ProductGroup[];
    pagination: Pagination;
  }>,
  includeVariants: boolean = true
) {
  const queryParams: Record<string, string | number> = {
    "page[number]": page,
    "page[size]": PER_PAGE,
  };

  if (includeVariants) {
    queryParams.include = "product_variants";
  }

  const query = queryString.stringify(queryParams);

  return useQuery(
    ["product-groups", id, query],
    () => getProductGroups(id, query),
    config
  );
}

export function useAllProductGroups(
  id: string,
  includeVariants: boolean = true
) {
  const queryParams: Record<string, string | number> = {
    "page[number]": 0,
    "page[size]": 500,
  };

  if (includeVariants) {
    queryParams.include = "product_variants";
  }

  const query = queryString.stringify(queryParams);

  return useQuery(["product-groups", id, query], () =>
    getProductGroups(id, query)
  );
}

export function useUpdateProductGroup(
  config?: UseMutationOptions<ProductGroup, ApiError, ProductGroup>
) {
  return useMutation(updateProductGroup, config);
}

interface CreateProductGroupPayload {
  group: ProductGroup;
  shopId: string;
}

export function useCreateProductGroup(
  config?: UseMutationOptions<
    WretcherResponse,
    ApiError,
    CreateProductGroupPayload
  >
) {
  return useMutation(createProductGroup, config);
}

interface AddToProductGroupPayload {
  groupId: string;
  variants: ProductVariant[];
}

export function useAddToProductGroup(
  config?: UseMutationOptions<
    WretcherResponse,
    ApiError,
    AddToProductGroupPayload
  >
) {
  return useMutation(addToProductGroup, config);
}

export function useDeleteProductGroup(
  config?: UseMutationOptions<WretcherResponse, ApiError, string>
) {
  return useMutation<WretcherResponse, ApiError, string>(
    deleteProductGroup,
    config
  );
}

export function useSignOut(config?: UseMutationOptions) {
  return useMutation(signOut, config);
}

export function useCreateUpload(
  config?: UseMutationOptions<UploadUrls, ApiError, CreateUploadParams>
) {
  return useMutation(createUpload, config);
}

export function useBundles(
  id: string,
  search: string,
  page: number | null,
  config?: UseQueryOptions<{ bundles: Bundle[]; pagination: Pagination }>
) {
  const searchParams = Boolean(search) ? { query: search } : {};
  const queryParams = { include: "product_variants", ...searchParams };
  const query = generateQueryString(queryParams, page, BUNDLES_PER_PAGE);

  return useQuery(["bundles", id, query], () => getBundles(id, query), config);
}

export function useUpdateBundle(
  config?: UseMutationOptions<Bundle, ApiError, Bundle>
) {
  return useMutation(updateBundle, config);
}

export function useDeleteBundle(
  config?: UseMutationOptions<WretcherResponse, ApiError, string>
) {
  return useMutation<WretcherResponse, ApiError, string>(deleteBundle, config);
}

interface CreateBundlePayload {
  bundle: Bundle;
  shopId: string;
}

export function useCreateBundle(
  config?: UseMutationOptions<WretcherResponse, ApiError, CreateBundlePayload>
) {
  return useMutation(createBundle, config);
}

export function useCreateBrandChannel(
  config?: UseMutationOptions<BrandChannel, ApiError, CreateBrandChannel>
) {
  return useMutation(createBrandChannel, config);
}

export function useAnalytics(
  id: string,
  range: DateRange,
  config?: UseQueryOptions<DashboardAnalytics>
) {
  return useQuery(
    ["analytics", id, range],
    () => getAnalytics(id, range),
    config
  );
}

export function useAnalyticsDashboard(
  id: string,
  property: string,
  config?: UseQueryOptions<AnalyticsDashboard>
) {
  return useQuery<AnalyticsDashboard>(
    // Although the property isn't really used in the query, we need to pass it to invalidate the results!
    // This guarantees we get a fresh signature that is still valid when rendering the iframes
    ["analytics", id, property],
    () => getAnalyticsDashboard(id),
    {
      ...config,
      cacheTime: 0,
      // We dont' want to re-fetch on focus because
      // this causes the iframe mode dashboard to reload, which is expensive
      refetchOnWindowFocus: false,
    }
  );
}

export function useIdentity() {
  return useQuery(["identity"], () => getIdentity(), {
    retry: 1,
    refetchOnWindowFocus: false,
  });
}

export function useFontsFromShop(
  shopPublicDomain: string,
  fetchFonts: boolean,
  setFetchFonts: (set: boolean) => void,
  setAvailableFonts: (fonts: Font[]) => void
) {
  const { isLoading } = useQuery(
    ["fontsFromShop", shopPublicDomain],
    () => getShopFonts(`https://${shopPublicDomain}`),
    {
      enabled: fetchFonts,
      onSuccess: (data) => {
        setAvailableFonts(data);
        setFetchFonts(false);
      },
    }
  );

  return { isLoading };
}

export function useCodes(
  id: string,
  queryParams?: { [key: string]: string },
  config?: UseQueryOptions<Code[]>
) {
  const query = generateQueryString(
    { include: "product_variant", ...queryParams },
    null,
    PER_PAGE
  );
  return useQuery(["codes", id, query], () => getCodes(id, query), config);
}

export function useBrandChannels(
  id: string,
  config?: UseQueryOptions<BrandChannel[]>
) {
  const queryParams = {
    "page[number]": null,
    "page[size]": PER_PAGE,
  };

  const query = queryString.stringify(queryParams);

  return useQuery(
    ["brand-channels", id],
    () => getBrandChannels(id, query),
    config
  );
}

export function useCode(id: string, config?: UseQueryOptions<Code>) {
  return useQuery(["code", id], () => getCode(id), config);
}

export interface CreateCodePayload {
  shopId: string;
  productVariant: ProductVariant;
}

export function useCreateCode(
  config?: UseMutationOptions<Code, ApiError, CreateCodePayload>
) {
  return useMutation(createCode, config);
}

export function useUpdateCode(
  config?: UseMutationOptions<WretcherResponse, ApiError, string>
) {
  return useMutation(updateCode, {
    ...config,
  });
}

export interface UseSearchCollectionsParams {
  query: string;
  shopId: string;
}

export function useSearchCollections(params: UseSearchCollectionsParams) {
  return useQuery(
    ["collections", params.shopId, params.query],
    () => searchCollections(params),
    {
      enabled: Boolean(params.query),
      refetchOnWindowFocus: false,
    }
  );
}

export function useCreateCollection(config?: UseQueryOptions<any, ApiError>) {
  return useMutation(createCollection, config);
}

export function useCurrentShopId() {
  const params = useParams();
  const { id } = params;
  return id as string;
}

interface UseSettingsParams {
  shopId: string;
  cacheKey: string;
}

export function useSettings<T>(params: UseSettingsParams) {
  const { cacheKey, shopId } = params;
  const queryClient = useQueryClient();
  const { showToast } = useToastStore();

  const fetchFn = buildSettingsFetchFn<T>({ shopId, endpoint: cacheKey });
  const updateFn = buildSettingsUpdateFn<T>({ shopId, endpoint: cacheKey });

  const mutation = useMutation<T, Error, T>(updateFn, {
    onSuccess: async () => {
      await queryClient.invalidateQueries([cacheKey, shopId]);
      showToast("Settings updated successfully.");
    },
    onError: () => {
      showToast("Failed to update settings.");
    },
  });

  const query = useQuery<T>([cacheKey, shopId], fetchFn, {
    refetchOnWindowFocus: false,
  });

  return {
    mutation,
    query,
  };
}
