import React from "react";
import ReactDOM from "react-dom";
import "@shopify/polaris/dist/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { AppProvider } from "@shopify/polaris";
import { QueryClient, QueryClientProvider } from "react-query";
import { Link } from "react-router-dom";
import { ReactQueryDevtools } from "react-query/devtools";
import { LinkLikeComponent } from "@shopify/polaris/dist/types/latest/src/utilities/link";
import Analytics from "analytics";

import googleAnalytics from "@analytics/google-analytics";
import segmentPlugin from "@analytics/segment";

import { AnalyticsProvider } from "use-analytics";
import "./index.css";
import App from "./App";
import { theme } from "./theme";
import { makeServer } from "./mirage";
import { GA_MEASUREMENT_ID, SEGMENT_WRITE_KEY } from "./constants";

if (process.env.REACT_APP_ENABLE_MIRAGE === "true") {
  makeServer();
}

/* Initialize analytics & load plugins */
const analytics = Analytics({
  app: "northstar",
  plugins: [
    googleAnalytics({
      trackingId: GA_MEASUREMENT_ID,
    }),
    segmentPlugin({
      writeKey: SEGMENT_WRITE_KEY,
    }),
  ],
});

const queryClient = new QueryClient();

const IS_EXTERNAL_LINK_REGEX = /^(?:[a-z][a-z\d+.-]*:|\/\/)/;

const LinkComponent: LinkLikeComponent = ({
  children,
  url = "",
  external,
  ref,
  ...rest
}) => {
  if (url === "#") {
    return (
      // HACK to support "parent" nav items in the <AppNavigation /> component
      // @ts-ignore
      <button {...rest}>{children}</button>
    );
  }

  if (external || IS_EXTERNAL_LINK_REGEX.test(url)) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url} {...rest}>
      {children}
    </Link>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AppProvider
          linkComponent={LinkComponent}
          theme={theme}
          i18n={enTranslations}
        >
          <App />
        </AppProvider>
      </QueryClientProvider>
    </AnalyticsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
