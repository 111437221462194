import shop from "./shop";
import shopSettings from "./shop-settings";
import bundle from "./bundle";
import productVariant from "./variant";
import productGroup from "./product-group";
import collection from "./collection";
import code from "./code";
import brandChannel from "./brand-channel";

export const factories = {
  shop,
  productVariant,
  productGroup,
  brandChannel,
  bundle,
  collection,
  code,
  shopSettings,
};
