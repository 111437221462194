import { Datum } from "@nivo/line";
import * as z from "zod";

export const variantSchemaStatus = [
  "pending",
  "approved",
  "rejected",
  "archived",
] as const;

export enum ButtonPreference {
  rounded = "rounded",
  square = "square",
}

export enum ImageCropPreference {
  cover = "cover",
  contain = "contain",
}

export enum SendingStatus {
  manual = "manual",
  automatic = "automatic",
}

export enum FontSource {
  shopify = "shopify",
  typekit = "typekit",
  google = "google",
}
export const FontSourceEnum = z.nativeEnum(FontSource);

export const FontSchema = z
  .object({
    fontFamily: z.string(),
    fontCssUrl: z.string(),
    fontWeight: z.string().nullable(),
    fontStyle: z.string().nullable(),
    fontSource: FontSourceEnum,
  })
  .nullable();

export type Font = z.infer<typeof FontSchema>;

export const ButtonPreferenceEnum = z.nativeEnum(ButtonPreference);
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export type ButtonPreferenceEnum = z.infer<typeof ButtonPreferenceEnum>;
export type VariantStatus = typeof variantSchemaStatus[number];

export const ImageCropPreferenceEnum = z.nativeEnum(ImageCropPreference);
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export type ImageCropPreferenceEnum = z.infer<typeof ImageCropPreferenceEnum>;

export const SendingStatusEnum = z.nativeEnum(SendingStatus);
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type
export type SendingStatusEnum = z.infer<typeof SendingStatusEnum>;

export const ShopSchema = z.object({
  id: z.string(),
  name: z.string(),
  color: z.string(),
  lightColor: z.string().optional().nullable(),
  orderConfirmationOptInPreference: z.enum(["modal", "embedded"]),
  tosUrl: z.string(),
  policyUrl: z.string(),
  supportEmail: z.string(),
  processingDelayDays: z.number(),
  snoozeInterval: z.number(),
  isPreview: z.boolean(),
  notificationsEnabled: z.boolean(),
  sendingStatus: SendingStatusEnum,
  isLightMode: z.boolean(),
  logoUrl: z.string(),
  gaTrackerId: z.string(),
  minCheckoutQuantity: z.number(),
  discountCode: z.string(),
  previewUrl: z.string(),
  slackWebhook: z.string(),
  publicDomain: z.string(),
  klaviyoPublicKey: z.string(),
  cartBaseUrl: z.string(),
  messaging: z.object({
    reminder: z.string(),
    replenish: z.string(),
    welcome: z.string(),
  }),
  optinSettings: z.object({
    discountText: z.string().optional().nullable(),
    discountUrl: z.string().optional().nullable(),
    termsOfService: z.string().optional().nullable(),
    modal: z.object({
      title: z.string(),
      body: z.string(),
      discountText: z.string().optional().nullable(),
      showDiscountText: z.boolean(),
      showRepeatLogo: z.boolean(),
    }),
    page: z.object({
      title: z.string(),
      body: z.string(),
    }),
  }),
  styles: z.object({
    scripts: z.string(),
    font: z.string(),
    availableFonts: z.array(FontSchema),
    selectedFont: FontSchema.nullable(),
    buttonPreference: ButtonPreferenceEnum,
    imageCropPreference: ImageCropPreferenceEnum,
  }),
});

export type Shop = z.infer<typeof ShopSchema>;

export const VariantSchema = z.object({
  id: z.string(),
  archivedAt: z.string().nullable(),
  originId: z.string(),
  originProductId: z.string(),
  cartToken: z.string(),
  imageUrl: z.string(),
  title: z.string(),
  hidden: z.boolean(),
  replenishmentInterval: z.number().nullable(),
  shopifyUrl: z.string(),
  inventoryManagement: z.string().or(z.null()),
  inventoryQuantity: z.number().or(z.null()),
  inventoryPolicy: z.string().or(z.null()),
  listedOnRepeatSalesChannel: z.boolean(),
  listedOnOnlineStore: z.boolean(),
  bundleId: z.number().or(z.null()),
  hasSimilarProducts: z.boolean(),
  reviewedAt: z.string().nullable(),
});

export type ShopSettings = z.infer<typeof ShopSettingsSchema>;

export const ShopSettingsSchema = z.object({
  availableFonts: z.array(FontSchema).nullable(),
  buttonPreference: z.string().nullable(),
  cartBaseUrl: z.string().nullable(),
  color: z.string().nullable(),
  currency: z.string().nullable(),
  discountCode: z.string().nullable(),
  gaTrackerId: z.string().nullable(),
  imageCropPreference: z.string().nullable(),
  locale: z.string().nullable(),
  logoUrl: z.string().nullable(),
  minCheckoutQuantity: z.number().default(1),
  name: z.string(),
  optinModalBody: z.string().nullable(),
  optinModalDiscountText: z.string().nullable(),
  optinModalShowDiscountText: z.boolean().nullable(),
  optinModalShowRepeatLogo: z.boolean().nullable(),
  optinModalTitle: z.string().nullable(),
  optinPageBody: z.string().nullable(),
  optinPageTitle: z.string().nullable(),
  optinSettingsDiscountText: z.string().nullable(),
  optinSettingsDiscountUrl: z.string().nullable(),
  optinSettingsTermsOfService: z.string().nullable(),
  policyUrl: z.string().nullable(),
  previewUrl: z.string().nullable(),
  primaryButtonTextColor: z.string(),
  processingDelayDays: z.number().default(0),
  rejectSubscriptionTagEnabled: z.boolean().default(true),
  repeatSmsEnabled: z.boolean().default(false),
  repeatSmsMessagingReminder: z.string().nullable(),
  repeatSmsMessagingReplenish: z.string().nullable(),
  repeatSmsMessagingWelcome: z.string().nullable(),
  repeatSmsOptInEnabled: z.boolean().default(false),
  repeatSmsSendingStatus: SendingStatusEnum,
  repeatSmsSupportEmail: z.string().nullable(),
  selectedFontCssUrl: z.string().nullable(),
  selectedFontFamily: z.string().nullable(),
  selectedFontSource: FontSourceEnum.nullable(),
  selectedFontStyle: z.string().nullable(),
  selectedFontWeight: z.string().nullable(),
  sellingPlansEnabledAt: z.string().nullable(),
  shopId: z.number(),
  shopPublicDomain: z.string(),
  snoozeInterval: z.number().default(7),
  tosUrl: z.string().nullable(),
});

export type ProductVariant = z.infer<typeof VariantSchema>;

export const ProductGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  productVariants: z.array(VariantSchema),
});

export type ProductGroup = z.infer<typeof ProductGroupSchema>;

export const BundleProductVariantSchema = z.object({
  id: z.string(),
  productVariant: VariantSchema,
});

export const BundleSchema = z.object({
  id: z.string(),
  productVariantId: z.string(),
  productVariants: z.array(VariantSchema),
});

export type Bundle = z.infer<typeof BundleSchema>;

export const CodeSchema = z.object({
  id: z.string(),
  qrCodePng: z.string(),
  qrCodeSvg: z.string(),
  encodedUrl: z.string(),
  targetUrl: z.string(),
  productVariant: VariantSchema.optional(),
  archivedAt: z.string().or(z.null()),
});

export type Code = z.infer<typeof CodeSchema>;

export const BrandChannelSchema = z.object({
  platform: z.string(),
  channelType: z.string(),
  slug: z.string().optional(),
});

export type BrandChannel = z.infer<typeof BrandChannelSchema>;

export interface CreateBrandChannel {
  brandChannel: BrandChannel;
  shopId: string;
}

export interface CreateUploadParams {
  file: File;
}

export interface UploadUrls {
  presignedUrl: string;
  publicUrl: string;
}

export interface SessionShop {
  id: string;
  name: string;
}

export interface ApiError {
  status: string;
  text?: string;
}

export interface AnalyticsDashboard {
  summaryAnalyticsEmbedUrl: string;
  emailAnalyticsEmbedUrl: string;
  insightsEmbedUrl: string;
  smsAnalyticsEmbedUrl: string;
}

export type AnalyticsDashboardEmbedUrl = keyof AnalyticsDashboard;

export interface DashboardAnalyticsPayload {
  count: number | string;
  data: Datum[];
}

export interface DashboardAnalytics {
  numSignups: DashboardAnalyticsPayload;
  signupRate: DashboardAnalyticsPayload;
}

export interface DateRange {
  start: Date;
  end: Date;
}

export interface Pagination {
  currentPage: number;
  hasNext: boolean;
  hasPrevious: boolean;
  totalPages: number;
}

export interface Collection {
  id: string;
  title: string;
}

export interface User {
  id: string;
  role: string;
  shopId?: number;
  features: string[];
}
