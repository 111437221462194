import React, { Suspense } from "react";
import { useIdentity } from "./hooks";
import { PageLoader } from "./components/PageLoader";
import { User } from "./interfaces";
import { LOGIN_URL } from "./constants";

const AuthenticatedApp = React.lazy(() => import("./AuthenticatedApp"));

interface LazyAuthenticatedAppProps {
  user?: User;
}

function LazyAuthenticatedApp({ user }: LazyAuthenticatedAppProps) {
  return (
    <Suspense fallback={<PageLoader />}>
      {user !== undefined && <AuthenticatedApp user={user} />}
    </Suspense>
  );
}

function App() {
  const { data: user, isSuccess: isAuthenticated, isFetching } = useIdentity();

  if (isFetching) {
    return <PageLoader />;
  }

  if (isAuthenticated) {
    return <LazyAuthenticatedApp user={user} />;
  }

  window.location.href = LOGIN_URL;

  return null;
}

export default App;
