import create from "zustand";
import { ApiError } from "../interfaces";

type State = {
  toast?: string;
  isError: boolean;
  showToast: (toast: string, isError?: boolean) => void;
  showErrorToast: (error: ApiError) => void;
  hideToast: () => void;
};

export const useToastStore = create<State>((set) => ({
  toast: undefined,
  isError: false,
  showErrorToast: (error) =>
    set(() => ({
      toast: `A ${
        error?.status || "unknown"
      } error occurred while creating the bundle.`,
      isError: true,
    })),
  showToast: (toast, isError = false) => set(() => ({ toast, isError })),
  hideToast: () => set(() => ({ toast: undefined, isError: false })),
}));
