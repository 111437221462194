import { Factory } from "fishery";
import Chance from "chance";

import {
  ButtonPreference,
  Shop,
  SendingStatus,
  ImageCropPreference,
} from "../interfaces";
import { constants } from "../lib";

// So that random data is consistent across sessions
const chance = new Chance(constants.CHANCE_SEED);

export default Factory.define<Shop>(() => ({
  id: chance.guid(),
  name: chance.company(),
  color: chance.color(),
  lightColor: chance.color(),
  orderConfirmationOptInPreference: "embedded",
  policyUrl: chance.url(),
  supportEmail: chance.email(),
  processingDelayDays: chance.integer(),
  snoozeInterval: chance.integer(),
  cartBaseUrl: chance.url(),
  tosUrl: chance.url(),
  isPreview: chance.bool(),
  isLightMode: chance.bool(),
  notificationsEnabled: chance.bool(),
  sendingStatus: SendingStatus.manual,
  logoUrl: chance.url(),
  gaTrackerId: "U-12234-22",
  minCheckoutQuantity: chance.integer({ min: 0, max: 100 }),
  discountCode: chance.name(),
  previewUrl: chance.url(),
  slackWebhook: chance.url(),
  publicDomain: chance.url(),
  klaviyoPublicKey: chance.name(),
  messaging: {
    reminder: chance.sentence(),
    replenish: chance.sentence(),
    welcome: chance.sentence(),
  },
  optinSettings: {
    discountText: chance.sentence(),
    discountUrl: chance.url(),
    termsOfService: chance.sentence(),
    modal: {
      title: chance.sentence(),
      body: chance.sentence(),
      discountText: chance.sentence(),
      showDiscountText: chance.bool(),
      showRepeatLogo: chance.bool(),
    },
    page: {
      title: chance.sentence(),
      body: chance.sentence(),
    },
  },
  styles: {
    scripts: chance.paragraph(),
    font: chance.name(),
    selectedFont: null,
    availableFonts: [],
    buttonPreference: ButtonPreference.rounded,
    imageCropPreference: ImageCropPreference.cover,
  },
}));
