import wretch from "wretch";
import { Deserializer, Serializer } from "ts-jsonapi";
import { omit } from "lodash-es";
import { BUNDLES_PER_PAGE, PER_PAGE } from "../constants";
import {
  Bundle,
  DashboardAnalytics,
  DateRange,
  Pagination,
  ProductVariant,
  ProductGroup,
  Shop,
  Font,
  Code,
  Collection,
  AnalyticsDashboard,
  UploadUrls,
  CreateUploadParams,
  BrandChannel,
  CreateBrandChannel,
  User,
  ShopSettings,
} from "../interfaces";
import { CreateCodePayload } from "../hooks";
import { UseSearchCollectionsParams } from "../hooks";

type PaginatedResponse = {
  meta?: {
    pagination?: {
      current: number;
      records: number;
    };
  };
};

const JSONApiDeserializer = new Deserializer({ keyForAttribute: "camelCase" });

export const EntitySerializer = <T>(
  name: string,
  values: Partial<T>,
  attributes = Object.keys(values)
) => {
  return new Serializer(name, {
    id: "id",
    attributes,
    keyForAttribute: "camelCase",
  }).serialize(values);
};

const ProductVariantSerializer = (values: Partial<ProductVariant>) => {
  return new Serializer("productVariants", {
    id: "id",
    attributes: Object.keys(values),
    keyForAttribute: "camelCase",
  }).serialize(values);
};

const api = wretch(process.env.REACT_APP_API_BASE_URL).options({
  credentials: "include",
  mode: "cors",
});

const getPagination = (res: PaginatedResponse, perPage = PER_PAGE) => {
  const {
    meta: {
      pagination: { current: currentPage = 1, records: totalRecords = 1 } = {},
    } = {},
  } = res;

  const totalPages = Math.ceil(totalRecords / perPage);
  const hasNext = currentPage !== totalPages && totalRecords > 0;
  const hasPrevious = currentPage !== 1;
  return { hasNext, hasPrevious, currentPage, totalPages };
};

export function getShop(id: string) {
  return api
    .url(`/api/v2/shops/${id}`)
    .get()
    .json<Shop>((res) => {
      return JSONApiDeserializer.deserialize(res);
    });
}

export function getShopSettings(shopId: string) {
  return api
    .url(`/api/v2/shops/${shopId}/shop_settings`)
    .get()
    .json<ShopSettings>((res) => {
      return JSONApiDeserializer.deserialize(res);
    });
}

interface UpdateShopSettingsParams {
  shopId: string;

  values: Partial<ShopSettings>;
}

export function updateShopSettings({
  shopId,
  values,
}: UpdateShopSettingsParams) {
  const serialized = EntitySerializer<ShopSettings>("shop_settings", values);

  return api
    .url(`/api/v2/shops/${shopId}/shop_settings`)
    .patch(serialized)
    .json<ShopSettings>((res) => {
      return JSONApiDeserializer.deserialize(res);
    });
}

export function getIdentity() {
  return api
    .url(`/api/v2/identity`)
    .get()
    .json<User>((res) => {
      return JSONApiDeserializer.deserialize(res);
    });
}

interface UpdateShopParams {
  id: string;
  values: Shop;
}

export function updateShop({ id, values }: UpdateShopParams) {
  const serialized = EntitySerializer<Shop>("shops", values);

  return api
    .url(`/api/v2/shops/${id}`)
    .patch(serialized)
    .json<Shop>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function searchShops(query: string) {
  return api
    .url(`/api/v2/shops`)
    .query({ query })
    .get()
    .json<Shop[]>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getProductVariants(id: string, query: string) {
  return api
    .url(`/api/v2/shops/${id}/product-variants`)
    .query(query)
    .get()
    .json<{ variants: ProductVariant[]; pagination: Pagination }>((res) => {
      return {
        pagination: getPagination(res),
        variants: JSONApiDeserializer.deserialize(res),
      };
    });
}

export function updateProductVariant(productVariant: Partial<ProductVariant>) {
  const serialized = ProductVariantSerializer(productVariant);

  return api
    .url(`/api/v2/product-variants/${productVariant.id}`)
    .patch(serialized)
    .json<ProductVariant>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getProductGroups(id: string, query: string) {
  return api
    .url(`/api/v2/shops/${id}/product-groups`)
    .query(query)
    .get()
    .json<{ productGroups: ProductGroup[]; pagination: Pagination }>((res) => {
      return {
        productGroups: JSONApiDeserializer.deserialize(res),
        pagination: getPagination(res),
      };
    });
}

export function updateProductGroup(productGroup: ProductGroup) {
  const serialized = new Serializer("product-groups", {
    id: "id",
    attributes: Object.keys(productGroup),
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: false,
      attributes: ["id", "name"],
    },
  }).serialize(productGroup);

  return api
    .url(`/api/v2/product-groups/${productGroup.id}`)
    .patch(serialized)
    .json<ProductGroup>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

interface CreateProductGroupParams {
  group: ProductGroup;
  shopId: string;
}

export function createProductGroup(params: CreateProductGroupParams) {
  const { group, shopId } = params;

  const stubGroup = omit(group, "id");

  const serialized = new Serializer("product-groups", {
    id: "",
    attributes: Object.keys(stubGroup),
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: true,
      attributes: ["id", "name"],
    },
  }).serialize(stubGroup);

  return api
    .url(`/api/v2/shops/${shopId}/product-groups/`)
    .post(serialized)
    .res();
}

interface AddToProductGroupParams {
  groupId: string;
  variants: ProductVariant[];
}

export function addToProductGroup(params: AddToProductGroupParams) {
  const { groupId, variants } = params;
  const payload = {
    id: groupId,
    productVariants: variants,
  };

  const serialized = new Serializer("product-groups", {
    id: "",
    attributes: ["id", "productVariants"],
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: true,
      attributes: ["id"],
    },
  }).serialize(payload);

  return api
    .url(`/api/v2/product-groups/${groupId}/add-variants`)
    .post(serialized)
    .res();
}

export function deleteProductGroup(id: string) {
  return api.url(`/api/v2/product-groups/${id}`).delete().res();
}

export function signOut() {
  return api.url("/api/v2/identity").delete().res();
}

export function getBundles(id: string, query: string) {
  return api
    .url(`/api/v2/shops/${id}/bundles`)
    .query(query)
    .get()
    .json<{ bundles: Bundle[]; pagination: Pagination }>((res) => {
      return {
        bundles: JSONApiDeserializer.deserialize(res),
        pagination: getPagination(res, BUNDLES_PER_PAGE),
      };
    });
}

export function updateBundle(bundle: Bundle) {
  const serialized = new Serializer("bundles", {
    id: "id",
    attributes: Object.keys(bundle),
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: true,
      attributes: ["id"],
    },
  }).serialize(bundle);

  return api
    .url(`/api/v2/bundles/${bundle.id}`)
    .patch(serialized)
    .json<Bundle>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function deleteBundle(id: string) {
  return api.url(`/api/v2/bundles/${id}`).delete().res();
}

interface CreateBundleParams {
  bundle: Bundle;
  shopId: string;
}

export function createBundle(params: CreateBundleParams) {
  const { bundle, shopId } = params;

  const stubBundle = omit(bundle, "id");

  const serialized = new Serializer("bundles", {
    id: "",
    attributes: Object.keys(stubBundle),
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: true,
      attributes: ["id"],
    },
  }).serialize(stubBundle);

  return api.url(`/api/v2/shops/${shopId}/bundles/`).post(serialized).res();
}

export function createUpload(params: CreateUploadParams) {
  const { file } = params;

  return api
    .url("/api/v2/uploads/")
    .post({
      filename: file.name,
    })
    .json<UploadUrls>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getAnalytics(shopId: string, range: DateRange) {
  return api
    .url(`/api/v2/shops/${shopId}/analytics`)
    .query({
      start: range.start.toISOString(),
      end: range.end.toISOString(),
    })
    .get()
    .json<DashboardAnalytics>();
}

export function getAnalyticsDashboard(shopId: string) {
  return api
    .url(`/api/v2/shops/${shopId}/dashboard-metrics`)
    .get()
    .json<AnalyticsDashboard>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getShopFonts(shopUrl: string) {
  return wretch("/.netlify/functions/scrape_fonts")
    .query({ url: shopUrl })
    .get()
    .json<Font[]>();
}

export function getCodes(id: string, query: string) {
  return api
    .url(`/api/v2/shops/${id}/codes`)
    .query(query)
    .get()
    .json<Code[]>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getBrandChannels(id: string, query: string) {
  return api
    .url(`/api/v2/shops/${id}/brand-channels`)
    .query(query)
    .get()
    .json<BrandChannel[]>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function createBrandChannel(params: CreateBrandChannel) {
  const serialized = new Serializer("brand_channels", {
    id: "",
    attributes: ["channelType", "platform", "shop_id"],
    keyForAttribute: "camelCase",
  }).serialize(params.brandChannel);

  return api
    .url(`/api/v2/shops/${params.shopId}/brand-channels`)
    .post(serialized)
    .json<BrandChannel>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function getCode(id: string) {
  return api
    .url(`/api/v2/codes/${id}`)
    .query({
      include: "product_variant",
    })
    .get()
    .json<Code>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function createCode(params: CreateCodePayload) {
  const { productVariant } = params;

  const serialized = new Serializer("codes", {
    id: "",
    attributes: ["productVariant"],
    keyForAttribute: "camelCase",
    productVariants: {
      ref: "id",
      included: true,
      attributes: ["id"],
    },
  }).serialize({
    productVariant: productVariant.id,
  });

  return api
    .url(`/api/v2/codes`)
    .query({
      include: "product_variant",
    })
    .post(serialized)
    .json<Code>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

export function updateCode(id: string) {
  let archived = { id: id, archivedAt: new Date().toISOString() };
  const serialized = EntitySerializer<Code>("codes", archived);
  return api.url(`/api/v2/codes/${id}`).patch(serialized).res();
}

export function searchCollections(
  params: UseSearchCollectionsParams
): Promise<Collection[]> {
  const { shopId, query } = params;
  return api
    .url(`/api/v2/shops/${shopId}/collections/search`)
    .query({ query })
    .get()
    .json<Collection[]>((res) => {
      const json = JSONApiDeserializer.deserialize(res);
      return json;
    });
}

interface CreateCollectionParams {
  collections: string[];
  shopId: string;
  name: string;
}

export function createCollection(params: CreateCollectionParams) {
  const { name, shopId, collections } = params;
  return api
    .url(`/api/v2/product-groups/import`)
    .post({
      shop_id: shopId,
      name,
      collections,
    })
    .res();
}

export function buildSettingsFetchFn<T>(params: {
  shopId: string;
  endpoint: string;
}) {
  return function () {
    return api
      .url(`/api/v2/shops/${params.shopId}/settings/${params.endpoint}`)
      .get()
      .json<T>((res) => {
        const json = JSONApiDeserializer.deserialize(res);
        return json;
      });
  };
}

export function buildSettingsUpdateFn<T>(params: {
  shopId: string;
  endpoint: string;
}) {
  return function (values: T) {
    const serialized = EntitySerializer<Shop>("shops", values);

    return api
      .url(`/api/v2/shops/${params.shopId}/settings/${params.endpoint}`)
      .patch(serialized)
      .json<T>();
  };
}

export function requestAnalyticsData(params: { shopId: string }) {
  return api
    .url(`/api/v2/shops/${params.shopId}/notify_internal/data_request`)
    .post()
    .res();
}
