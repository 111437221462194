import { Factory } from "fishery";
import Chance from "chance";

import { Collection } from "../interfaces";
import { constants } from "../lib";

// So that random data is consistent across sessions
const chance = new Chance(constants.CHANCE_SEED);

export default Factory.define<Collection>(() => ({
  id: chance.guid(),
  title: chance.animal(),
}));
