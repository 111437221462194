import { Layout, SkeletonBodyText, SkeletonPage } from "@shopify/polaris";
import * as React from "react";

export const PageLoader: React.FC = () => {
  return (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <SkeletonBodyText lines={9} />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );
};
