import { StateCreator } from "zustand";
import produce from "immer";
import * as React from "react";

export const constants = {
  DEBOUNCE_AMOUNT: 300,
  CHANCE_SEED: "fixed-seed",
};

export const daysToSeconds = (numDays: number) => numDays * 86400;
export const secondsToDays = (numSeconds: number) => numSeconds / 86400;

export const immer =
  <T extends {}>(
    config: StateCreator<T, (fn: (draft: T) => void) => void>
  ): StateCreator<T> =>
  (set, get, api) =>
    config((fn) => set(produce(fn) as (state: T) => T), get, api);

export const usePagination = (
  searchParams: URLSearchParams,
  setSearchParams: (
    params: URLSearchParams,
    opts: { [key: string]: boolean }
  ) => void
) => {
  const initialPage = Number(searchParams.get("page") || "1");
  const [page, setPage] = React.useState(initialPage);
  const nextPage = () => {
    setPage(page + 1);
    searchParams.set("page", String(page + 1));
    setSearchParams(searchParams, { replace: true });
  };
  const prevPage = () => {
    setPage(Math.max(1, page - 1));
    searchParams.set("page", String(page - 1));
    setSearchParams(searchParams, { replace: true });
  };
  const resetPage = () => {
    setPage(1);
  };

  return { nextPage, prevPage, page, resetPage };
};

export const maybePluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

/** Download contents as a file
 * Source: https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 */
export function downloadBlob(
  content: string,
  filename: string,
  contentType: string
) {
  // Create a blob
  var blob = new Blob([content], { type: contentType });
  var url = URL.createObjectURL(blob);

  // Create a link to download it
  var pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", filename);
  pom.click();
}
