import format from "date-fns/format";
import addWeeks from "date-fns/addWeeks";

export const monthlySignups = [
  { x: "2020-01-01", y: 5 },
  { x: "2020-02-01", y: 7 },
  { x: "2020-03-01", y: 20 },
  { x: "2020-04-01", y: 35 },
  { x: "2020-05-01", y: 36 },
  { x: "2020-06-01", y: 70 },
  { x: "2020-07-01", y: 120 },
  { x: "2020-08-01", y: 125 },
  { x: "2020-09-01", y: 150 },
  { x: "2020-10-01", y: 160 },
  { x: "2020-11-01", y: 165 },
  { x: "2020-12-01", y: 200 },
];

const start = new Date(2020, 0, 1);
const formatString = "yyyy-MM-dd";

export const signupRate = [
  { x: format(start, formatString), y: 6 },
  { x: format(addWeeks(start, 2), formatString), y: 6 },
  { x: format(addWeeks(start, 4), formatString), y: 12 },
  { x: format(addWeeks(start, 6), formatString), y: 5 },
  { x: format(addWeeks(start, 8), formatString), y: 8 },
  { x: format(addWeeks(start, 10), formatString), y: 12 },
  { x: format(addWeeks(start, 12), formatString), y: 16 },
];
