import { User } from "../interfaces";

export const ADMIN_USER = "AdminUser";
export const BRAND_USER = "BrandUser";

export const userHasAdminAccess = (user?: User) => {
  if (!user) return false;

  return user.role === ADMIN_USER;
};
