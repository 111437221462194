import { Factory } from "fishery";
import Chance from "chance";

import { ProductGroup } from "../interfaces";
import { constants } from "../lib";

// So that random data is consistent across sessions
const chance = new Chance(constants.CHANCE_SEED);

export default Factory.define<ProductGroup>(({ sequence }) => ({
  id: chance.guid(),
  name: `Product Group – ${sequence}`,
  productVariants: [],
}));
