import { Factory } from "fishery";
import variantFactory from "./variant";
import Chance from "chance";

import { Code } from "../interfaces";

const chance = new Chance();

export default Factory.define<Code>(() => {
  const id = chance.guid();
  return {
    id,
    qrCodePng: chance.string(),
    qrCodeSvg: chance.string(),
    encodedUrl: chance.string(),
    targetUrl: chance.string(),
    productVariant: variantFactory.build(),
    archivedAt: null,
  };
});
