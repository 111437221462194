import { ThemeConfig } from "@shopify/polaris/dist/types/latest/src/utilities/theme";

export const theme: ThemeConfig = {
  colors: {},
  logo: {
    width: 124,
    topBarSource: "/logo.png",
    contextualSaveBarSource: "/logo.png",
    url: "https://www.getrepeat.io/?utm_source=northstar&utm_medium=northstar",
    accessibilityLabel: "Repeat",
  },
};
