// @ts-nocheck
import {
  createServer,
  JSONAPISerializer,
  Model,
  belongsTo,
  hasMany,
} from "miragejs";
import { matchSorter } from "match-sorter";
import { chunk, isArray } from "lodash-es";

import { factories } from "../factories";
import { monthlySignups, signupRate } from "../fixtures";
import { PER_PAGE } from "../constants";
import { Chance } from "chance";
import { ADMIN_USER } from "../lib/user";

type MirageEnv = "development" | "test";

function fuzzySearch(original, q) {
  let hay = original.toLowerCase(),
    i = 0,
    n = -1,
    l;
  q = q.toLowerCase();
  for (; (l = q[i++]); ) if (!~(n = hay.indexOf(l, n + 1))) return false;
  return true;
}

const paginate = (json, request) => {
  if (!isArray(json.data)) {
    return json;
  }

  const pageNumber = parseInt(request.queryParams["page[number]"] || 1);
  const pageSize = parseInt(request.queryParams["page[size]"] || PER_PAGE);

  const offset = (pageNumber - 1) * pageSize;
  const limit = offset + Math.min(pageSize, json.data.length - offset);
  const totalPages = Math.ceil(json.data.length / pageSize);

  const pagination = {
    current: pageNumber,
    records: json.data.length,
    next: pageNumber === totalPages + 1 ? null : pageNumber + 1,
  };

  json.meta = { pagination };
  json.data = json.data.slice(offset, limit);

  return json;
};

export function makeServer(environment: MirageEnv = "development") {
  const server = createServer({
    environment,
    serializers: {
      application: JSONAPISerializer.extend({
        serialize(object, request) {
          let json = JSONAPISerializer.prototype.serialize.apply(
            this,
            arguments
          );
          return paginate(json, request);
        },
      }),
    },
    models: {
      shop: Model.extend({
        productVariants: hasMany(),
        productGroups: hasMany(),
        bundles: hasMany(),
        codes: hasMany(),
        collections: hasMany(),
      }),
      bundle: Model.extend({
        shop: belongsTo(),
        productVariants: hasMany(),
      }),
      productVariant: Model.extend({
        shop: belongsTo(),
        bundle: belongsTo(),
        collection: belongsTo(),
      }),
      productGroup: Model.extend({
        productVariants: hasMany(),
        shop: belongsTo(),
      }),
      code: Model.extend({
        shop: belongsTo(),
        productVariant: belongsTo(),
      }),
      collection: Model.extend({
        shop: belongsTo(),
        productVariants: hasMany(),
      }),
    },
    seeds(server) {
      const shops = factories.shop.buildList(50);

      shops.forEach((shop) => {
        const persistedShop = server.create("shop", shop);
        const productVariants = factories.productVariant.buildList(15);

        const collections = factories.collection.buildList(5);

        const abcProductGroup = factories.productGroup.build();
        const xyzProductGroup = factories.productGroup.build();

        const persistedProductVariants = productVariants.map(
          (productVariant) => {
            return server.create("productVariant", {
              ...productVariant,
              shop: persistedShop,
            });
          }
        );
        const [abcProducts, xyzProducts] = chunk(persistedProductVariants, 5);

        server.create("productGroup", {
          ...abcProductGroup,
          shop: persistedShop,
          productVariants: abcProducts,
        });

        server.create("productGroup", {
          ...xyzProductGroup,
          shop: persistedShop,
          productVariants: xyzProducts,
        });

        server.create("bundle", {
          shop: persistedShop,
          productVariantId: abcProducts[0].id,
          productVariants: [abcProducts[1]],
        });

        collections.forEach((collection) => {
          server.create("collection", {
            shop: persistedShop,
            productVariants: persistedProductVariants,
            ...collection,
          });
        });
      });
    },
    routes() {
      this.get("/.netlify/functions/scrape_fonts", function () {
        return [
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "700",
            fontStyle: "italic",
            fontFamily: "Baskerville No 2",
            fontSource: "shopify",
          },
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "400",
            fontStyle: "normal",
            fontFamily: "Amiri",
            fontSource: "shopify",
          },
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "Amiri",
            fontSource: "shopify",
          },
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "700",
            fontStyle: "normal",
            fontFamily: "Amiri",
            fontSource: "shopify",
          },
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "400",
            fontStyle: "italic",
            fontFamily: "Amiri",
            fontSource: "shopify",
          },
          {
            fontCssUrl:
              "//cdn.shopify.com/s/files/1/0247/2961/6432/t/1/assets/theme.scss.css?v=12484298589353210794",
            fontWeight: "700",
            fontStyle: "italic",
            fontFamily: "Amiri",
            fontSource: "shopify",
          },
        ];
      });

      this.urlPrefix =
        process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";
      this.namespace = "/api/v2";

      this.get("/identity", function () {
        return {
          data: {
            id: 1,
            attributes: {
              role: ADMIN_USER,
            },
          },
        };
      });

      this.delete("/identity", function () {
        return {
          data: {},
        };
      });

      this.resource("shops");

      this.get("/shops", function (schema, request) {
        const { query } = request.queryParams;

        const shops = schema.shops.all();
        const json = this.serialize(shops);

        return {
          data: matchSorter(json.data, query, {
            keys: ["attributes.name"],
          }),
        };
      });

      this.get("/shops/:id/product-groups", function (schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);
        return shop.productGroups;
      });

      this.get("/shops/:id/bundles", function (schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);
        return shop.bundles;
      });

      this.post("/shops/:id/product-groups", function (schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);
        const { data } = JSON.parse(request.requestBody);

        const productVariants = schema.productVariants.find(
          data.relationships.productVariants.data.map((p) => p.id)
        );

        const productGroup = schema.productGroups.create({
          ...data.attributes,
          productVariants,
          shop,
        });

        productGroup.save();
      });

      this.resource("bundles");

      this.post("/shops/:id/bundles", function (schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);
        const { data } = JSON.parse(request.requestBody);
        const { productVariantId } = data.attributes;

        const productVariants = schema.productVariants.find(
          data.relationships.productVariants.data.map((p) => p.id)
        );

        const bundle = server.create("bundle", {
          shop,
          productVariantId,
          productVariants,
        });

        return bundle;
      });

      if (process.env.REACT_APP_ENABLE_MIRAGE !== "true") {
        this.passthrough((request) => {
          return true;
        });
      }

      this.resource("product-variants");

      this.get("shops/:id/product-variants", function (schema, request) {
        const { queryParams } = request;
        const { id } = request.params;
        const shop = schema.shops.find(id);

        const search = queryParams.query;
        const statusFilter = queryParams["filter[repeat_status_eq]"];
        const archivedAtFilter = queryParams["filter[archived_at_not_null]"];

        let variants;

        if (archivedAtFilter) {
          variants = shop.productVariants.filter((variant) => {
            return variant.attrs.archivedAt !== null;
          });
        } else if (statusFilter) {
          variants = shop.productVariants.filter((variant) => {
            return variant.attrs.repeatStatus === statusFilter;
          });
        }

        return variants.filter((variant) => {
          if (!search) return true;
          return fuzzySearch(variant.attrs.title, search);
        });
      });

      this.resource("product-groups");

      this.get("shops/:id/analytics", function (schema, request) {
        return {
          numSignups: {
            count: 6134,
            data: monthlySignups,
          },
          signupRate: {
            count: "10.34%",
            data: signupRate,
          },
        };
      });

      this.get("shops/:id/codes", function (schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);

        return shop.codes;
      });

      this.get("/codes/:id");

      this.post("/codes", function (schema, request) {
        const { data } = JSON.parse(request.requestBody);
        const variantId = data.attributes.productVariant;
        const productVariant = schema.productVariants.find(variantId);

        const code = schema.codes.create({
          productVariantId: variantId,
          encodedUrl: Chance().url(),
          targetUrl: Chance().url(),
          qrCodeSvg:
            "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg",
          qrCodePng:
            "https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg",
          shop: productVariant.shop,
        });

        code.save();
        return code;
      });

      this.resource("collections");

      this.get("shops/:id/collections/search", function (schema, request) {
        const { query } = request.queryParams;
        const { id } = request.params;
        const shop = schema.shops.find(id);

        const collections = shop.collections;

        const json = this.serialize(collections);

        return {
          data: matchSorter(json.data, query, {
            keys: ["attributes.title"],
          }),
        };
      });

      this.post("/product-groups/import", function (schema, request) {
        const { shop_id, name } = JSON.parse(request.requestBody);
        const shop = schema.shops.find(shop_id);

        server.create("productGroup", {
          name,
          shop,
        });
      });

      function defaultSettingsHandler(schema, request) {
        const { id } = request.params;
        const shop = schema.shops.find(id);

        return shop;
      }

      function defaultSettingsPatchHandler(schema, request) {
        const { id } = request.params;
        const { data } = JSON.parse(request.requestBody);

        const shop = schema.shops.find(id);

        shop.update(data.attributes);
        shop.save();
        return shop;
      }

      this.get("/shops/:id/settings/cart", defaultSettingsHandler);
      this.get("/shops/:id/settings/sms", defaultSettingsHandler);
      this.get("/shops/:id/settings/email", defaultSettingsHandler);
      this.get("/shops/:id/settings/styles", defaultSettingsHandler);
      this.get("/shops/:id/settings/general", defaultSettingsHandler);

      this.patch("/shops/:id/settings/cart", defaultSettingsPatchHandler);
      this.patch("/shops/:id/settings/sms", defaultSettingsPatchHandler);
      this.patch("/shops/:id/settings/email", defaultSettingsPatchHandler);
      this.patch("/shops/:id/settings/styles", defaultSettingsPatchHandler);
      this.patch("/shops/:id/settings/general", defaultSettingsPatchHandler);

      this.get("/shops/:id/dashboard-metrics", function () {
        return {
          data: {
            attributes: {
              summaryAnalyticsEmbedUrl: "",
              emailAnalyticsEmbedUrl: "",
              insightsEmbedUrl: "",
              smsAnalyticsEmbedUrl: "",
            },
          },
        };
      });
    },
  });

  return server;
}
