import { Factory } from "fishery";
import Chance from "chance";
import { BrandChannel } from "../interfaces";
import { constants } from "../lib";

// So that random data is consistent across sessions
const chance = new Chance(constants.CHANCE_SEED);

export default Factory.define<BrandChannel>(({ sequence }) => ({
  id: chance.guid(),
  platform: `Channel${sequence}`,
  channelType: "SMS",
}));
