import create from "zustand";
import { mapValues, has } from "lodash-es";

import { immer } from "../lib";

type State = {
  allVisible: boolean;
  visibilityMap: Record<string, boolean>;
  createModalOpen: boolean;
  openCreateModal: () => void;
  closeCreateModal: () => void;
  toggleAllVisibility: () => void;
  toggleVisibility: (id: string) => void;
  registerVisibility: (id: string) => void;
};

export const useProductGroupStore = create<State>(
  immer((set) => ({
    allVisible: false,
    visibilityMap: {},
    createModalOpen: false,
    registerVisibility: (id) =>
      set((state) => {
        if (!has(state.visibilityMap, id)) {
          state.visibilityMap[id] = false;
        }
      }),
    toggleVisibility: (id) =>
      set((state) => {
        state.visibilityMap[id] = !state.visibilityMap[id];
      }),
    toggleAllVisibility: () =>
      set((state) => {
        const current = state.allVisible;
        state.allVisible = !current;
        state.visibilityMap = mapValues(state.visibilityMap, () => !current);
      }),
    openCreateModal: () =>
      set((state) => {
        state.createModalOpen = true;
      }),
    closeCreateModal: () =>
      set((state) => {
        state.createModalOpen = false;
      }),
  }))
);
