import { Factory } from "fishery";
import Chance from "chance";

import {
  ButtonPreference,
  ImageCropPreference,
  SendingStatus,
  ShopSettings,
} from "../interfaces";
import { constants } from "../lib";

// So that random data is consistent across sessions
const chance = new Chance(constants.CHANCE_SEED);
export default Factory.define<ShopSettings>(() => ({
  availableFonts: null,
  buttonPreference: ButtonPreference.rounded,
  cartBaseUrl: chance.url(),
  color: chance.color(),
  currency: chance.string(),
  discountCode: chance.name(),
  gaTrackerId: "U-12234-22",
  imageCropPreference: ImageCropPreference.cover,
  logoUrl: chance.url(),
  locale: chance.string(),
  minCheckoutQuantity: chance.integer({ min: 0, max: 100 }),
  name: chance.company(),
  optinModalBody: chance.sentence(),
  optinModalDiscountText: chance.sentence(),
  optinModalShowDiscountText: chance.bool(),
  optinModalShowRepeatLogo: chance.bool(),
  optinModalTitle: chance.sentence(),
  optinPageBody: chance.sentence(),
  optinPageTitle: chance.sentence(),
  optinSettingsDiscountText: chance.sentence(),
  optinSettingsDiscountUrl: chance.url(),
  optinSettingsTermsOfService: chance.sentence(),
  policyUrl: chance.url(),
  previewUrl: chance.url(),
  primaryButtonTextColor: "#ffffff",
  processingDelayDays: chance.integer(),
  rejectSubscriptionTagEnabled: chance.bool(),
  repeatSmsEnabled: chance.bool(),
  repeatSmsMessagingReminder: chance.sentence(),
  repeatSmsMessagingReplenish: chance.sentence(),
  repeatSmsMessagingWelcome: chance.sentence(),
  repeatSmsOptInEnabled: chance.bool(),
  repeatSmsSendingStatus: SendingStatus.manual,
  repeatSmsSupportEmail: chance.email(),
  selectedFontCssUrl: null,
  selectedFontFamily: null,
  selectedFontSource: null,
  selectedFontStyle: null,
  selectedFontWeight: null,
  sellingPlansEnabledAt: new Date().toDateString(),
  shopId: chance.integer(),
  shopPublicDomain: chance.url(),
  snoozeInterval: chance.integer(),
  tosUrl: chance.url(),
}));
