import { Factory } from "fishery";
import Chance from "chance";

import { ProductVariant } from "../interfaces";
import { daysToSeconds } from "../lib";

const chance = new Chance();

export default Factory.define<ProductVariant>(() => {
  const id = chance.guid();
  return {
    id,
    originId: chance.integer({ min: 100000000, max: 1000000000 }).toString(),
    originProductId: chance
      .integer({ min: 100000000, max: 1000000000 })
      .toString(),
    cartToken: chance.guid(),
    imageUrl: chance.url(),
    title: chance.animal(),
    archivedAt: chance.pickone([chance.string(), null, null]),
    replenishmentInterval: daysToSeconds(chance.integer({ min: 1, max: 10 })),
    hidden: chance.bool(),
    shopifyUrl: chance.url(),
    inventoryManagement: chance.string(),
    inventoryQuantity: chance.integer({ min: 0, max: 1000000 }),
    inventoryPolicy: "deny",
    listedOnOnlineStore: false,
    listedOnRepeatSalesChannel: false,
    bundleId: null,
    hasSimilarProducts: false,
    reviewedAt: chance.pickone([chance.string(), null, null]),
  };
});
