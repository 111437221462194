import { FontSource } from "./interfaces";

export const GA_MEASUREMENT_ID =
  process.env.REACT_APP_GA_MEASUREMENT_ID || "UA-162073481-3";

export const LOGIN_URL =
  process.env.REACT_APP_LOGIN_URL ||
  `http://backend.getrepeat.test/admin_users/sign_in`;

export const SEGMENT_WRITE_KEY =
  process.env.REACT_APP_SEGMENT_WRITE_KEY || "dummy-segment-key";

export const HIDDEN_FILTER = "filter[hidden_eq]";

export const ARCHIVED_AT_FILTER = "filter[archived_at_not_null]";
export const NOT_ARCHIVED_AT_FILTER = "filter[archived_at_null]";
export const ONE = "1";
export const PER_PAGE = Number(process.env.REACT_APP_DEFAULT_PAGE_SIZE || 25);
export const BUNDLES_PER_PAGE = Number(
  process.env.REACT_APP_BUNDLES_PAGE_SIZE || 25
);
export const VARIANT_PAGE_SIZE = Number(
  process.env.REACT_APP_VARIANT_PAGE_SIZE || 3000
);
export const VARIANT_SEARCH_PAGE_SIZE = Number(
  process.env.REACT_APP_VARIANT_SEARCH_PAGE_SIZE || 25
);
export const VARIANT_SEARCH_DEBOUNCE_MS = Number(
  process.env.REACT_APP_VARIANT_SEARCH_DEBOUNCE_MS || 300
);
export const GLEAMIN_FONT = {
  publicDomain: "gleamin.com",
  font: {
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Poppins",
    fontSource: FontSource.google,
    fontCssUrl: "https://fonts.googleapis.com/css2?family=Poppins&display=swap",
  },
};

export const BREWBIKE_FONT = {
  publicDomain: "brewbike.com",
  font: {
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Courier Prime",
    fontSource: FontSource.google,
    fontCssUrl:
      "https://fonts.googleapis.com/css2?family=Courier+Prime&display=swap",
  },
};

export const BETTER_NOT_YOUNGER_FONT = {
  publicDomain: "better-notyounger.com",
  font: {
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Lato",
    fontSource: FontSource.google,
    fontCssUrl: "https://fonts.googleapis.com/css2?family=Lato&display=swap",
  },
};

export const OSEA_FONT = {
  publicDomain: "oseamalibu.com",
  font: {
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "proxima-nova",
    fontSource: FontSource.typekit,
    fontCssUrl: "https://use.typekit.net/jmk8jih.css",
  },
};
